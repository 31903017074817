<template>
  <div class="WaterConfig">
    <div class="leftBG">
      <div class="content" :style="cssFontColor">
        <!-- :style="[cssObj, cssColumn, cssLine]" -->
        <div
          class="showContent csstop"
          ref="dataS"
          v-for="(items, index) in 10"
          :class="{ clsLeftss: index % 2 == 0 }"
          :style="getStyles(index)"
          :key="index"
        >
          <!-- 列间距 -->
          <div class="columncss" style="cssColumn">
            <!-- 行间距 -->
            <div class="linespacss" style="cssLine">
              <!-- 旋转角度 -->
              <div
                class="Rotaticss"
                :style="{ transform: `rotate(${saveData.rotationAngle}deg)` }"
              >
                <div
                  class="nameShow"
                  :style="cssAlpha"
                  v-for="(item, index) in labelsArr"
                  :key="index"
                >
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="WaterContent">
      <el-form
        :model="saveData"
        :rules="rules"
        ref="saveData"
        label-width="150px"
        class="demo-ruleForm"
        style="margin-top: 10px; margin-left: 3%"
      >
        <el-form-item :label="$t('AppManage.InterfaceWatermark.Name') + ':'" prop="name">
          <el-input
            size="small"
            v-model="saveData.name"
            style="width: 300px; margin-right: 10px"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('AppManage.InterfaceWatermark.WatermarkContent') + ':'"
          prop="waterFinaContent"
        >
          <el-checkbox-group
            v-model="saveData.waterFina.switch"
            @change="choose"
            style="width: 360px"
          >
            <template>
              <el-checkbox
                v-for="item in Options"
                :key="item.value"
                :label="item.value"
                >{{
                  $t(`AppManage.InterfaceWatermark.SelectOptions.${item.label}`)
                }}</el-checkbox
              >
            </template>
            <el-input
              size="small"
              v-model="saveData.waterFina.content"
              :disabled="iptDisabled"
              @input="showContents"
              style="width: 130px; display: inline-block; margin-left: 20px"
              maxlength="6"
              :placeholder="$t('AppManage.InterfaceWatermark.EnterPlaceholder')"
            >
            </el-input>
          </el-checkbox-group>
          <!-- <span style="z-index: 99">$t('AppManage.InterfaceWatermark.Custom')水印内容，最长6个字符</span> -->
        </el-form-item>
        <el-form-item
          :label="$t('AppManage.InterfaceWatermark.WatermarkSize') + '：'"
        >
          <el-slider
            v-model="saveData.fontSize"
            :min="minNum"
            :max="maxNum"
            @input="changeSize"
          ></el-slider>
        </el-form-item>
        <el-form-item
          :label="$t('AppManage.InterfaceWatermark.WordTransparency') + '：'"
        >
          <el-slider v-model="saveData.alpha" @input="changeAlpha"></el-slider>
        </el-form-item>
        <!-- v6.1.2 新增 -->
        <el-form-item :label="$t('AppManage.InterfaceWatermark.LineSpacing') + '（px）' + '：'">
          <el-slider
            v-model="saveData.linespacing"
            :min="minNumLine"
            :max="maxNumLine"
            @input="Linespacing"
          ></el-slider>
        </el-form-item>
        <el-form-item :label="$t('AppManage.InterfaceWatermark.ColumnSpacing') + '（px）' + '：'">
          <el-slider
            v-model="saveData.columnspacing"
            :min="minNumColumn"
            :max="maxNumColumn"
            @input="Columnspacing"
          ></el-slider>
        </el-form-item>
        <el-form-item :label="$t('AppManage.InterfaceWatermark.RotationAngle') + '：'">
          <el-slider
            v-model="saveData.rotationAngle"
            :min="minNumrotation"
            :max="maxNumrotation"
            @input="RotationAngle"
          ></el-slider>
        </el-form-item>
        <el-form-item
          :label="$t('AppManage.InterfaceWatermark.WordColor') + '：'"
        >
          <el-color-picker
            v-model="saveData.fontColor"
            color-format="hex"
            :predefine="predefineColors"
            @input="changeColor"
          >
          </el-color-picker>
          <!-- <span>点击左侧按钮选择颜色</span> -->
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="submitForm('saveData')"
            >{{ $t("public.Save") }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { log } from "console";
export default {
  props: {
    editID: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      iptDisabled: true,
      minNum: 12,
      maxNum: 36,
      predefineColors: [
        "#d81e06",
        "#f4ea2a",
        "#1afa29",
        "#1296db",
        "#13227a",
        "#d4237a",
        "#ffffff",
        "#e6e6e6",
        "#dbdbdb",
        "#cdcdcd",
        "#bfbfbf",
        "#8a8a8a",
        "#707070",
        "#515151",
        "#2c2c2c",
      ],
      saveData: {
        id: "",
        fontSize: 1,
        fontColor: "#1296db",
        name: "",
        waterFina: { sum: "24", switch: ["16", "8"], content: "" },
        alpha: 100,
        action: "add",
        linespacing: 10, // 行间距
        columnspacing: 10, // 列间距
        rotationAngle: 330, // 旋转角度
      },
      rules: {
        name: [
          {
            required: true,
            message: this.$t("AppManage.InterfaceWatermark.Placeholder"),
            trigger: "blur",
          },
          {
            min: 1,
            max: 20,
            message: this.$t("AppManage.InterfaceWatermark.NameLength"),
            trigger: "blur",
          },
        ],
        // waterFina: [{ required: true, message: "请选择水印内容", trigger: "blur" }],
        waterFinaContent: [
          {
            min: 1,
            max: 6,
            message: this.$t("AppManage.InterfaceWatermark.EnterLength"),
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              // console.log(value,'value请输入自定义内容');
              // console.log(this.saveData.waterFina.content,'this.saveData.waterFina.content请输入自定义内容');
              if (!this.iptDisabled && this.saveData.waterFina.content == "") {
                callback(new Error(this.$t('AppManage.InterfaceWatermark.ContentPlaceholder')));
              } else {
                callback();
              }
            },
            trigger: "blur", //这里还有blur触发方法； 如果设为none，那么只有this.$refs[formName]['validate']时才会验证
          },
          // { required: true, message: "请输入自定义内容", trigger: "blur" }
        ],
      },
      Options: [
        { value: "16", label: "用户名" },
        { value: "8", label: "部门名称" },
        { value: "4", label: "电话后四位" },
        { value: "2", label: "自定义" },
      ],
      sumArr: [],
      cssObj: {
        fontSize: "12px",
      },
      cssAlpha: {
        opacity: 0.1,
      },
      cssFontColor: {
        color: "#1296db",
      },
      margin: 15,
      margin1: {
        marginTop: "14px",
      },

      // v6.1.2 新增
      // 行间距
      minNumLine: 10,
      maxNumLine: 50,
      cssLine: {
        marginBottom: "50px",
      },
      linemargin: 10,
      marginspac: {
        marginLine: "9px",
      },
      // 列间距
      clsLeftss: {
        marginRight: "10px",
      },
      minNumColumn: 10,
      maxNumColumn: 50,
      cssColumn: {
        marginRight: "10px",
      },
      columnmargin: 10,
      margincolumn: {
        marginColumn: "9px",
      },
      //旋转角度
      minNumrotation: 0,
      maxNumrotation: 359,

      editData: {
        manage: 1,
        documents: ["287"],
        type: "",
        action: "edit",
        id: "30",
      },
      labelsArr: [],
    };
  },
  watch: {
    iptDisabled(newVal) {
      console.log(newVal, "newVal");
      if (newVal==true) {
        this.$refs.saveData.clearValidate(["waterFinaContent"]);
      }
    },
    deep: true,
  },
  created() {},
  mounted() {
    if (this.editID.id) {
      console.log(this.editID.waterFina.switch, "水印内容");
      // 假设 numbers 和 objects 是你的初始数组
      // let numbers = this.saveData.waterFina.switch;
      let objects = [
        { value: "16", label: this.$t('public.Username') },
        { value: "8", label: this.$t('AppManage.InterfaceWatermark.DepartmentName') },
        { value: "4", label: this.$t('AppManage.InterfaceWatermark.LastFourDigits') },
        { value: "2", label: this.$t('AppManage.InterfaceWatermark.Custom') },
      ];

      // 使用 map 函数将 numbers 转换为 labels
      let labels = this.editID.waterFina.switch.map(function (num) {
        // 遍历 objects 数组，找到 id 与 num 匹配的对象，并返回其 label 属性
        for (let obj of objects) {
          if (obj.value === num) {
            return obj.label;
          }
        }
        // 如果找不到匹配的对象，返回一个空字符串或其他默认值
        return "";
      });
      let index = labels.indexOf(this.$t('AppManage.InterfaceWatermark.Custom'));

      if (index !== -1) {
        labels[index] = this.editID.waterFina.content;
      }
      this.labelsArr = labels;
      this.saveData.fontSize = this.editID.fontSize;
      this.saveData.name = this.editID.name;
      this.saveData.fontColor = this.editID.fontColor;
      this.saveData.alpha = this.editID.alpha;
      this.saveData.linespacing = this.editID.rowSpacing;
      this.saveData.columnspacing = this.editID.columnSpacing;
      this.saveData.rotationAngle = this.editID.rotationAngle;
      // this.saveData.waterFina.switch = this.editID.waterFina.switch;
      // this.saveData.waterFina.sum = this.editID.waterFina.sum;
      if (this.editID.waterFina == null) {
        this.saveData.waterFina = {
          sum: this.saveData.waterFina.sum,
          switch: this.saveData.waterFina.switch,
          content: this.saveData.waterFina.content,
        };
      } else {
        this.saveData.waterFina = this.editID.waterFina;
        let found = this.saveData.waterFina.switch.find(
          (element) => element === "2"
        );
        //         console.log(found); // 输出：'2'
        if (found == 2) {
          this.iptDisabled = false;
        } else if (found == undefined) {
          this.iptDisabled = true;
        }
      }
      // this.saveData.action = "edit";
      this.saveData.id = this.editID.id;
      console.log(this.saveData.id);
      // this.saveData.documents = ["287"];
      this.cssFontColor.color = this.editID.fontColor;
      // this.selectOne();
    }
  },
  methods: {
    //获取回显数据
    async selectOne() {
      var parma = {
        manage: 1,
        documents: ["287"],
        type: "",
        action: "edit",
        id: this.editID,
      };
      console.log(parma);
      const res = await this.$axios.post(
        "/api/waterConfig/selectOne.do",
        parma,
        true
      );
      console.log(res, "回显数据");
      this.saveData.fontSize = res.data.fontSize;
      this.saveData.name = res.data.name;
      this.saveData.fontColor = res.data.fontColor;
      this.saveData.alpha = res.data.alpha;
      this.saveData.allinespacingpha = res.data.rowSpacing;
      this.saveData.columnspacing = res.data.columnSpacing;
      this.saveData.rotationAngle = res.data.rotationAngle;
      this.saveData.waterFina.switch = res.data.waterFina.switch;
      this.saveData.waterFina.sum = res.data.waterFina.sum;
      // JSON.stringify
      this.saveData.action = "edit";
      this.saveData.id = this.editID;
      console.log(this.saveData.id);
      this.saveData.documents = ["287"];
      this.cssFontColor.color = res.data.fontColor;
    },
    choose(val) {  
      let found = val.find((element) => element === "2");  
      if (found === "2") { // 注意这里应该比较字符串  
        this.iptDisabled = false;  
      } else if (found === undefined) {  
        this.iptDisabled = true;  
        this.saveData.waterFina.content = "";  
      }  
      this.sumArr = val;  
      let sum = 0;  
      val.forEach((item) => {  
        sum += Number(item);  
        this.saveData.waterFina.sum = sum;  
      });  
      
      // 从大到小排序 this.saveData.waterFina.switch  
      this.saveData.waterFina.switch.sort((a, b) => b - a); // 假设 switch 中的元素是数字  
      
      let objects = [  
        { value: "16", label: this.$t('public.Username') },  
        { value: "8", label: this.$t('AppManage.InterfaceWatermark.DepartmentName') },  
        { value: "4", label: this.$t('AppManage.InterfaceWatermark.LastFourDigits') },  
        { value: "2", label: this.$t('AppManage.InterfaceWatermark.Custom') },  
      ];  
      
      // 使用 map 函数将 numbers 转换为 labels  
      let labels = this.saveData.waterFina.switch.map(function (num) {  
        console.log(num, "num");
        for (let obj of objects) {  
          if (obj.value === num.toString()) { // 如果 num 是数字，可能需要转换为字符串进行比较  
            return obj.label;  
          }  
        }  
        return "";  
      });  
      
      let index = labels.indexOf(this.$t('AppManage.InterfaceWatermark.Custom'));  
      if (index !== -1) {  
        labels[index] = this.saveData.waterFina.content;  
      }  
      this.labelsArr = labels;  
    },
    showContents(value) {
      this.saveData.waterFina.content = value;
      let objects = [
        { value: "16", label: this.$t('public.Username') },
        { value: "8", label: this.$t('AppManage.InterfaceWatermark.DepartmentName') },
        { value: "4", label: this.$t('AppManage.InterfaceWatermark.LastFourDigits') },
        { value: "2", label: this.$t('AppManage.InterfaceWatermark.Custom') },
      ];

      // 使用 map 函数将 numbers 转换为 labels
      let labels = this.saveData.waterFina.switch.map(function (num) {
        // 遍历 objects 数组，找到 id 与 num 匹配的对象，并返回其 label 属性
        for (let obj of objects) {
          if (obj.value === num) {
            return obj.label;
          }
        }
        // 如果找不到匹配的对象，返回一个空字符串或其他默认值
        return "";
      });
      let index = labels.indexOf(this.$t('AppManage.InterfaceWatermark.Custom'));
      if (index !== -1) {
        labels[index] = this.saveData.waterFina.content;
      }
      this.labelsArr = labels;
    },
    //保存
    submitForm() {
      this.$refs.saveData.validate(async (valid) => {
        if (valid) {
          console.log(this.saveData);
          if (this.saveData.waterFina.switch == "") {
            this.$message({
              message: this.$t(
                "AppManage.InterfaceWatermark.SelectWatermarkContent"
              ),
              type: "error",
              offset: 100,
            });
            return false;
          } else {
            if (this.saveData.id) {
              var parma = {
                ids: [this.saveData.id], //添加时为空
                fontSize: String(this.saveData.fontSize),
                fontColor: this.saveData.fontColor,
                name: this.saveData.name,
                waterFina: this.saveData.waterFina,
                alpha: String(this.saveData.alpha),
                rowSpacing: String(this.saveData.linespacing), //行间距  可设置10～50px，默认10px
                columnSpacing: String(this.saveData.columnspacing), //列间距 可设置10～50px，默认10px
                rotationAngle: String(this.saveData.rotationAngle), //旋转角度 可设置0～359度
              };
              console.log(parma, "保存参数");
              const res = await this.$axios.post(
                "/httpServe/waterConfig/save",
                // this.saveData,
                parma,
                true
              );
              // console.log(this.saveData, "this.saveData");
              console.log(res, "编辑");
              this.flag = 1;
              this.$emit("addSave", res);
            } else {
              var parma = {
                ids: [], //添加时为空
                fontSize: String(this.saveData.fontSize),
                fontColor: this.saveData.fontColor,
                name: this.saveData.name,
                waterFina: this.saveData.waterFina,
                alpha: String(this.saveData.alpha),
                rowSpacing: String(this.saveData.linespacing), //行间距  可设置10～50px，默认10px
                columnSpacing: String(this.saveData.columnspacing), //列间距 可设置10～50px，默认10px
                rotationAngle: String(this.saveData.rotationAngle), //旋转角度 可设置0～359度
              };
              console.log(parma, "保存参数");
              const res = await this.$axios.post(
                "/httpServe/waterConfig/save",
                // this.saveData,
                parma,
                true
              );
              // console.log(this.saveData, "this.saveData");
              console.log(res, "新增");
              this.flag = 1;
              this.$emit("addSave", res);
            }
          }
        } else {
          console.log("error");
        }
      });
    },
    getStyles(index) {
      let styles = { ...this.cssObj, ...this.cssLine };
      if (index % 2 === 0) {
        // 你可以在这里添加你想要的偶数索引的样式   列间距
        styles.marginRight = this.clsLeftss.marginRight;
        this.clsLeftss.marginRight = this.saveData.columnspacing + "px";
        if (this.saveData.columnspacing >= 48) {
          styles.marginRight = 48 + "px";
        }
      }
      return styles;
    },
    // 字体大小
    changeSize() {
      this.saveData.fontSize = this.saveData.fontSize++;
      this.cssObj.fontSize = this.saveData.fontSize + "px";
      this.margin = this.margin++;
      this.margin1.marginTop = this.margin + "px";
      let objects = [
        { value: "16", label: this.$t('public.Username') },
        { value: "8", label: this.$t('AppManage.InterfaceWatermark.DepartmentName') },
        { value: "4", label: this.$t('AppManage.InterfaceWatermark.LastFourDigits') },
        { value: "2", label: this.$t('AppManage.InterfaceWatermark.Custom') },
      ];

      // 使用 map 函数将 numbers 转换为 labels
      let labels = this.saveData.waterFina.switch.map(function (num) {
        console.log(num, "num");
        // 遍历 objects 数组，找到 id 与 num 匹配的对象，并返回其 label 属性
        for (let obj of objects) {
          if (obj.value === num) {
            return obj.label;
          }
        }
        // 如果找不到匹配的对象，返回一个空字符串或其他默认值
        return "";
      });
      let index = labels.indexOf(this.$t('AppManage.InterfaceWatermark.Custom'));

      if (index !== -1) {
        labels[index] = this.saveData.waterFina.content;
      }
      this.labelsArr = labels;
    },
    // v6.1.2新增
    // 行间距
    Linespacing() {
      this.saveData.linespacing = this.saveData.linespacing++;
      this.cssLine.marginBottom =
        this.saveData.linespacing * 4 + "px" + " !important";
      this.linemargin = this.linemargin++;
      this.marginspac.marginLine = this.linemargin + "px";
    },
    // 列间距
    Columnspacing() {
      //  this.saveData.columnspacing = this.saveData.columnspacing++;
      //  console.log(this.saveData.columnspacing,'this.saveData.columnspacing');
      //  this.cssColumn.marginRight = this.saveData.columnspacing + "px";
      //  if(this.saveData.columnspacing>=48){
      //    this.cssColumn.marginRight = 48 + "px";
      //    console.log(this.saveData.columnspacing,'1111111');
      //  }
    },
    // 旋转角度
    RotationAngle(newValue) {
      console.log(newValue, "newValuenewValue");
      // 当滑块改变时，更新角度数据
      this.saveData.rotationAngle = newValue;
    },
    // 透明度
    changeAlpha() {
      // debugger
      this.saveData.alpha = this.saveData.alpha++;
      this.cssAlpha.opacity = this.saveData.alpha / 100;
      let objects = [
        { value: "16", label: this.$t('public.Username') },
        { value: "8", label: this.$t('AppManage.InterfaceWatermark.DepartmentName') },
        { value: "4", label: this.$t('AppManage.InterfaceWatermark.LastFourDigits') },
        { value: "2", label: this.$t('AppManage.InterfaceWatermark.Custom') },
      ];

      // 使用 map 函数将 numbers 转换为 labels
      let labels = this.saveData.waterFina.switch.map(function (num) {
        console.log(num, "num");
        // 遍历 objects 数组，找到 id 与 num 匹配的对象，并返回其 label 属性
        for (let obj of objects) {
          if (obj.value === num) {
            return obj.label;
          }
        }
        // 如果找不到匹配的对象，返回一个空字符串或其他默认值
        return "";
      });
      let index = labels.indexOf(this.$t('AppManage.InterfaceWatermark.Custom'));

      if (index !== -1) {
        labels[index] = this.saveData.waterFina.content;
      }
      this.labelsArr = labels;
    },
    // 改变颜色
    changeColor() {
      console.log(this.saveData.fontColor);
      this.cssFontColor.color = this.saveData.fontColor;
      let objects = [
        { value: "16", label: this.$t('public.Username') },
        { value: "8", label: this.$t('AppManage.InterfaceWatermark.DepartmentName') },
        { value: "4", label: this.$t('AppManage.InterfaceWatermark.LastFourDigits') },
        { value: "2", label: this.$t('AppManage.InterfaceWatermark.Custom') },
      ];
      // 使用 map 函数将 numbers 转换为 labels
      let labels = this.saveData.waterFina.switch.map(function (num) {
        console.log(num, "num");
        // 遍历 objects 数组，找到 id 与 num 匹配的对象，并返回其 label 属性
        for (let obj of objects) {
          if (obj.value === num) {
            return obj.label;
          }
        }
        // 如果找不到匹配的对象，返回一个空字符串或其他默认值
        return "";
      });
      let index = labels.indexOf(this.$t('AppManage.InterfaceWatermark.Custom'));

      if (index !== -1) {
        labels[index] = this.saveData.waterFina.content;
      }
      this.labelsArr = labels;
    },
  },
};
</script>

<style lang="scss" scoped>
.WaterConfig {
  width: 100%;
  height: 100%;
  padding: 10px;
  overflow: hidden auto;
}

.leftBG {
  position: relative;
  background: transparent url("~@/assets/img/onePlusBg.png") no-repeat;
  background-size: contain;
  width: 226px;
  height: 447px;
  overflow: hidden;
  display: inline-block;
  margin-left: 15px;
  margin-top: 8px;
  top: -70px;
  z-index: 999;
}

.content {
  width: 198px;
  height: 357px;
  overflow: hidden;
  position: absolute;
  top: 40px;
  // left: 15px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  text-align: center;
  color: #1296db;
  // font-weight: bold;
  margin-left: 14px;
}

.showContent {
  width: 75px;
  height: 24px;
  white-space: nowrap;
  word-spacing: 20px;
  font-size: 12px;
  opacity: 0.5;
  list-style: none;
  text-align: left;
  // padding-left: 10px;
  margin-bottom: 50px;
  margin-top: 5px;
}
.clsLeftss {
  margin-right: 10px;
}
.csstop {
  margin-bottom: 50px;
}
.nameShow {
  // overflow: hidden;
  // -webkit-transform: rotate(-15deg);
  display: block;
  // transform: rotate(-30deg);
}

// 行间距
.linespacss {
  margin-bottom: 10px;
}

// 列间距
.columncss {
  margin-left: 10px;
}

// 旋转角度
.Rotaticss {
  /* 使文字水平翻转以适应旋转 */
  transform-origin: 20px 30px;
}

.WaterContent {
  display: inline-block;
  position: relative;
  //   top: -125px;
}
::v-deep .el-slider__button-wrapper {
  z-index: 20 !important;
}
::v-deep .el-slider__runway {
  width: 100%;
  height: 6px;
  margin: 16px 0 16px 8px;
  background-color: #e4e7ed;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  vertical-align: middle;
}
</style>
