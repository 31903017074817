<template>
  <div>
    <!-- 界面水印 -->
    <div class="box" v-if="flag == 1">
      <el-row :gutter="5" class="mt15" style="display: flex">
        <el-col :span="3">
          <el-input
            v-model.trim="name"
            :placeholder="$t('AppManage.InterfaceWatermark.Placeholder')"
            size="small"
            @keyup.enter.native="search"
          ></el-input>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" class="searchbtn" size="small" @click="search">{{
            $t("public.Inquire")
          }}</el-button>
        </el-col>
      </el-row>
      <el-row style="padding: 10px">
        <el-col :span="24">
          <el-button type="primary" @click="addNew" class="addbtn" size="small">{{
            $t("public.New")
          }}</el-button>
          <el-button
            style="
              border-radius: 0px 3px 3px 0px;
              margin: 0px 0px 0px 0px;
              margin-left: 1px;
            "
            @click="delArr(delarr)"
            :type="typeInfo"
            :disabled="!hasSeleted"
            size="small"
            >{{ $t("public.Delete") }}</el-button
          >
        </el-col>
      </el-row>
      <!-- 列表 -->
      <div style="position: relative; margin-right: 10px">
        <el-table
          :cell-style="{ padding: '3px 0px' }"
          :header-row-style="{ height: '30px' }"
          ref="tableData"
          :data="tableData"
          :row-key="getRowKeys"
          lazy
          size="mini"
          stripe
          fit
          class="tableClass"
          tooltip-effect="dark"
          style="width: 100%; margin-left: 10px; padding-right: 10px"
          :default-sort="{ prop: 'updateTime', order: 'descending' }"
          @selection-change="delobj"
          @sort-change="sort"
        >
          <el-table-column
            type="selection"
            align="center"
            width="60"
            :reserve-selection="true"
          >
          </el-table-column>
          <el-table-column
            prop="name"
            :label="$t('AppManage.InterfaceWatermark.Name')"
            sortable="custom"
            width="180"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="groupFullName"
            :label="$t('public.Section')"
            sortable="custom"
            width="180"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              <span v-if="row.groupFullName">
                {{ row.groupFullName.substr(groupFullNameShowByIndex) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="waterFina"
            :label="$t('AppManage.InterfaceWatermark.WatermarkContent')"
            width="340"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              <span v-if="row.waterFina == null"> </span>
              <template v-else>
                <span v-for="(item, index) in row.waterFina.switch" :key="index">
                  <span v-if="item == 16">{{ $t("public.Username") }}&nbsp;</span>
                  <span v-if="item == 8"
                    >{{ $t("AppManage.InterfaceWatermark.DepartmentName") }}&nbsp;</span
                  >
                  <span v-if="item == 4"
                    >{{ $t("AppManage.InterfaceWatermark.LastFourDigits") }}&nbsp;</span
                  >
                  <span v-if="item == 2"
                    >{{ $t("AppManage.InterfaceWatermark.Custom") }}&nbsp;</span
                  ></span
                >
                <span
                  ><span v-if="row.waterFina.content">;&nbsp;</span
                  >{{ row.waterFina.content }}</span
                >
              </template>
            </template>
          </el-table-column>
          <el-table-column
            prop="updateTime"
            :label="$t('public.ModificationTime')"
            width="180"
            sortable="custom"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              {{ row.updateTime | dateformat }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('public.Controls')">
            <template slot-scope="scope">
              <span class="action_icon">
                <img
                  @click="edit(scope.row)"
                  src="@/assets/icon_write.png"
                  style="width: 15px; height: 15px; cursor: pointer"
                  :title="$t('public.Editor')"
                />
              </span>
              <span class="action_icon">
                <img
                  @click="del(scope.row.id)"
                  src="@/assets/icon_del.png"
                  style="width: 15px; height: 15px; cursor: pointer"
                  :title="$t('public.Delete')"
                />
              </span>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <pagination
          style="margin-left: 10px"
          :page="currentPage"
          :limit="pageSize"
          :total="total"
          :pageSizes="[10, 20, 30, 40]"
          v-on:handleChildGetList="handleParentGetList"
        ></pagination>
      </div>
    </div>
    <!-- 新增 -->
    <addWaterConfig v-if="flag == 2" @addSave="saveFlag" :editID="editData" />
    <!-- 保存成功的弹框 -->
    <dialog-info
      :dialogInfo="Successdialog"
      @handleClose="handleClose"
      @determine="determine"
      :titleInfo="seccess"
      :width="'240px'"
    />
  </div>
</template>
<script>
import pagination from "@/components/pagination/page";
import naviGation from "@/components/hearder/index";
import dialogInfo from "@/components/promptMessage/index";
import Eldialog from "@/components/elDialog";
import addWaterConfig from "./components/addWaterConfig.vue";
export default {
  components: {
    pagination,
    naviGation,
    dialogInfo,
    Eldialog,
    addWaterConfig,
  },
  data() {
    return {
      typeInfo: "info", // 删除按钮默认灰色
      flag: 1,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      groupFullNameShowByIndex: "",
      name: "",
      seccess: "",
      editID: "",
      sorts: "w.updateTime desc",
      orderColume: "updateTime",
      orderRule: "DESC",
      Successdialog: false,
      delarr: [],
      tableData: [],
      editData: {},
    };
  },
  computed: {
    hasSeleted() {
      return this.delarr && this.delarr.length;
    },
  },
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem("groupFullNameShowByIndex");
    this.getDataList();
  },
  methods: {
    getRowKeys(row) {
      return row.id;
    },

    // 查询
    async search() {
      if (this.name.length > 0) {
        this.currentPage = 1;
      }
      var parma = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        orderColume: this.orderColume,
        orderRule: this.orderRule,
        name: this.name,
      };
      const res = await this.$axios.post(
        "/httpServe/waterConfig/getDataInfo",
        parma,
        true
      );
      this.tableData = res.data.content;
      this.total = res.data.total;
    },
    // 列表
    async getDataList() {
      var parma = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        orderColume: this.orderColume,
        orderRule: this.orderRule,
        name: this.name,
      };
      const res = await this.$axios.post(
        "/httpServe/waterConfig/getDataInfo",
        parma,
        true
      );
      this.tableData = res.data.content;
      this.total = res.data.total;
    },
    // 排序
    sort(column) {
      if (column.prop == "updateTime") {
        if (column.order == "ascending") {
          this.orderRule = "ASC";
        } else {
          this.orderRule = "DESC";
        }
        this.orderColume = column.prop;
      } else if (column.prop == "name") {
        if (column.order == "ascending") {
          this.orderRule = "ASC";
        } else {
          this.orderRule = "DESC";
        }
        this.orderColume = column.prop;
      } else if (column.prop == "groupFullName") {
        if (column.order == "ascending") {
          this.orderRule = "ASC";
        } else {
          this.orderRule = "DESC";
        }
        this.orderColume = column.prop;
      }
      this.getDataList();
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.currentPage = page;
      this.pageSize = limit;
      // 调用查询方法
      this.getDataList();
    },
    // 选中数组 批量删除
    delobj(obj) {
      this.delarr = obj;
      this.typeInfo = "primary";
      if (this.delarr.length == 0) {
        this.typeInfo = "info";
      }
    },
    delArr(obj) {
      var ids = [];
      obj.forEach((item) => {
        ids.push(item.id);
      });
      var param = { ids: ids };
      console.log(param);
      this.$confirm(
        this.$t("AppManage.InterfaceWatermark.SureToDel"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          customClass: "persdsd",
          type: "warning",
        }
      )
        .then(async () => {
          const res = await this.$axios.post(
            "/httpServe/waterConfig/delete",
            param,
            true
          );
          if (res.data == -1) {
            this.$message({
              message: this.$t("AppManage.InterfaceWatermark.Used"),
              type: "error",
              offset: 100,
            });
          } else {
            this.$message({
              message: this.$t("public.SuccessfullyDeleted"),
              type: "success",
              offset: 100,
            });
            this.$refs.tableData.clearSelection();
            this.currentPage = 1;
            this.getDataList();
          }
        })
        .catch(() => {
          return false;
        });
    },
    // 删除
    del(id) {
      this.$confirm(
        this.$t("AppManage.InterfaceWatermark.SureToDel"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          customClass: "persdsd",
          type: "warning",
        }
      )
        .then(async () => {
          var parma = { ids: [id] };
          console.log(parma);
          const res = await this.$axios.post(
            "/httpServe/waterConfig/delete",
            parma,
            true
          );
          if (res.data == -1) {
            this.$message({
              message: this.$t("AppManage.InterfaceWatermark.Used"),
              type: "error",
              offset: 100,
            });
          } else {
            this.$message({
              message: this.$t("public.SuccessfullyDeleted"),
              type: "success",
              offset: 100,
            });
            this.$refs.tableData.clearSelection();
            this.currentPage = 1;
            this.getDataList();
          }
        })
        .catch(() => {
          return false;
        });
    },
    // 新增
    addNew() {
      this.editData.id = "";
      this.editData = {};
      this.flag = 2;
    },
    // 新增弹框
    saveFlag(val) {
      if (val.errorCode == "12295") {
        this.$message({
          message: this.$t("AppManage.InterfaceWatermark.SameName"),
          type: "error",
          offset: 100,
        });
      } else if (val.errorCode == "65450") {
        this.$message({
          message: this.$t("AppManage.InterfaceWatermark.SameName"),
          type: "error",
          offset: 100,
        });
      } else if (val.data == -1) {
        this.$message({
          message: this.$t("AppManage.InterfaceWatermark.SameName"),
          type: "error",
          offset: 100,
        });
      } else {
        this.flag = 1;
        this.name = "";
        this.$message({
          message: this.$t("public.SuccessfullySaved"),
          type: "success",
          offset: 100,
        });
        this.getDataList();
      }
    },
    // 回显
    edit(val) {
      console.log(val, "回显");
      this.editData = val;
      this.flag = 2;
    },
    // 弹框
    handleClose() {
      this.Successdialog = false; //提示消息  关闭
    },
    determine() {
      this.Successdialog = false; //提示消息  关闭
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-button + .el-button {
  margin-left: 1px;
}

.mt15 {
  padding: 10px 0 0 10px;
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
</style>